.cover {
    position: relative;
    height: 70vh;
    display: flex;
   justify-content: flex-start;
   align-items: center;
   background-attachment: fixed;
   background-size: cover;
}

.cover .cover-img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.cover-img img {
     width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -111;
    object-position: bottom;
}
.cover-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); 
     /* background-color: rgba(184, 127, 58, 0.3);  */
    z-index: 0;
}
.cover-img:hover::before{
    background-color: rgba(0, 0, 0, 0.5); 
    transition: 0.5s ease-in-out;
}
.cover-content {
    position: relative;
     width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 8rem;
    /* row-gap: 4rem; */
}

.cover-content h1 {
color: rgba(255, 255, 255, 0.90);
font-size: 60px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.cover-content h2 {
    color: rgba(255, 255, 255, 0.90);
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media screen and (max-width: 1000px){
    .cover
    {
        height: 60vh;
    }
    .cover-content {
        width: 70%;
        margin-left: 8rem;
        margin-top: 2rem;
        /* row-gap: 4rem; */
    }
     .cover-content h1 {

    font-size: 50px;
    }
    .cover-content h2 {
        font-size: 20px;
    }
}
@media screen and (max-width: 900px){
    .cover-content h1 {

    font-size: 45px;
    }

.cover-content h2 {
font-size: 18px;
}
}
@media screen and (max-width: 750px){
    .cover
    {
        height: 45vh;
    }
}
@media screen and (max-width: 650px){
    .cover-content {
        width: 70%;
        margin-left: 4rem;
    }
    .cover-content h1 {

font-size: 35px;
}

.cover-content h2 {
font-size: 16px;
}
}
@media screen and (max-width: 500px){
    /* .cover
    {
        height: 35vh;
    } */
    .cover-content {
     width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 2rem;
    /* row-gap: 4rem; */
}
     .cover-content h1 {
        font-size: 30px;
    }

.cover-content h2 {
font-size: 16px;
}
}
@media screen and (max-width: 400px){
    .cover-content {
        margin-top: 4rem;
    }
     .cover-content h1 {
        font-size: 25px;
    }

.cover-content h2 {
font-size: 14px;
}
}