.product_sec
{
    padding: 2rem 0rem 2rem 0rem;
    margin: 2rem 0rem 2rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 2rem;
}

.product_div
{
    display: flex;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
    column-gap: 0rem;
    width: 80%;
}
.product_card
{
    position: relative;
    display: flex;
    height: 17rem;
    width: 33%;
    cursor: pointer;
}
.product_card img
{
    height: 100%;
   width: 100%;
    object-fit: cover;
}
.card1 img
{
    border-radius: 10px 100px 10px 100px;
}
.card2 img
{
    border-radius: 80px 80px 80px 80px;
}
.card3 img
{
    border-radius: 100px 10px 100px 10px;
}
.card1
{
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 10px 100px 10px 100px;
}
.card2
{
    justify-content:center;
    align-items: flex-end;
    border-radius: 80px 80px 80px 80px;
}
.card2 h1
{
    text-align: center;
}
.card3
{
    justify-content: flex-start;
    align-items: flex-end;
    border-radius: 100px 10px 100px 10px;
}
.product_card img
{
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}

.heading-cover
{
    position: absolute;
    width: 100%;
    
} 
.product_card h1
{
   
    /* background-color: rgb(234, 234, 234,0.4); */
    background-color: #ffffff80;
    /* background-color: rgb(235, 235, 235, 0.8); */
    padding: 1rem 2rem;
    color: #182268;
     font-size: 30px;
     font-family: "Times New Roman", serif  ;
}
/* .heading1 h1
{
    border-radius: 10px 10px 10px 100px;
}
.heading2 h1
{
     border-radius: 50px 50px 50px 50px;
}
.heading3 h1
{
     border-radius: 10px 10px 100px 10px;
} */
.textile_head
{
    text-align: right;
}
.home_imp_head
{
    text-align: right;
}
.leaf
{
    width: 4rem;
    align-self: flex-start;
}
.leaf2
{
    width: 4rem;
}
.leaf3
{
    width: 4rem;
    align-self: flex-end;
}

.product_tagline
{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    width: 80%;
}
.product_tagline h2{
    color: #182268;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
}

.sec_back{
    background-color: #F1F0F0;
}

/********************** INDIVIDUAL PRODUCTS ***************************/

.prod_container
{
    display: flex;
    width: 80%;
    column-gap: 4rem;
}
.prod_reverse
{
    flex-direction: row-reverse;
}
.prod_container img
{
    width: 37.5%;
   object-fit: cover;
}
.main-img-1 img
{
    border-radius: 100px 10px 100px 10px;
}
.main-img-2 img
{
    border-radius: 10px 100px 10px 100px;
}
.prod_content
{
    width: 62.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 2rem;
}
.content_reverse
{
    justify-content: center;
    align-items: flex-end;
}
.prod_content h1
{
    color: #182268;
    text-align: left;
}
.prod_content p{
    font-size: 20px;
    text-align: left;
}
.content_reverse h1{
    text-align: right;
}
.content_reverse p
{
    text-align: right;
}

@media screen and (max-width: 1100px)
{
    .product_card
    {
        height: 18rem;
    }
}

@media screen and (max-width: 950px){
    .product_card h1
   {
    font-size: 28px;
   }
   .product_tagline h2
   {
    font-size: 28px;
   }
    .prod_container
   {
    column-gap: 2rem;
    width: 90%;
   }
   .prod_content
   {
    row-gap: 1rem;
   }
   .prod_content h1
   {
    font-size: 32px;
   }
   .prod_content p
   {
    font-size: 18px;
   }
}

@media screen and (max-width: 850px){
    .product_card
    {
        height: 16rem;
    }
   .product_card h1
   {
    font-size: 26px;
   }
   .product_tagline h2
   {
    font-size: 26px;
   }
   .leaf2
   {
    width: 3rem;
   }
    .prod_content
   {
    width: 50%;
   }
   .prod_content h1
   {
    font-size: 30px;
   }
   .prod_content p
   {
    font-size: 16px;
   }
}
@media screen and (max-width: 770px){
    .first_sec
    {
        padding: 0;
    }
     .product_sec
    {
        margin: 1rem 0rem 1rem 0rem;
    }
    .product_div
    {
        width: 90%;
    }
}
@media screen and (max-width: 700px){
    .product_card
    {
        height: 14rem;
    }
    .product_card h1
   {
    font-size: 24px;
    padding: 1rem 1rem 1rem 1rem;
   }
   .leaf,.leaf2,.leaf3
   {
    width: 3rem;
   }
   .product_tagline
   {
    width: 100%;
    column-gap: 0.5rem;
   }
   .product_tagline h2
   {
    font-size: 24px;
   }

    .prod_container
    {
        column-gap: 1rem;
    }
    .prod_container img
    {
        width: 50%;
    }
    .prod_content h1
   {
    font-size: 28px;
   }
   .prod_content p
   {
    font-size: 15px;
   }
}
@media screen and (max-width: 550px){
    .product_card
    {
        height: 12rem;
    }
     .product_card h1
   {
    font-size: 24px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
   }
}
@media screen and (max-width: 510px){
    .product_div{
        flex-direction: column;
        row-gap: 2rem;
    }
    .product_card
    {
        width: 90%;
        height: 10rem;
    }
    .product_card img
    {
    border-radius: 70px 70px 70px 70px;
    }
    .product_card h1
    {
        text-align: center;
    }
    .leaf,.leaf3
    {
        display:none;
    }
    .product_tagline h2
    {
        margin: 0;
    }
    .leaf2
   {
    width: 2rem;
   }
    .prod_container
   {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
   }
   .prod_container img {
    width: 80%;
    border-radius: 70px 70px 70px 70px;
   }
   .prod_content
   {
    width: 90%;
    justify-content: center;
    align-items: center;
   }
    .prod_content h1
   {
    text-align: center;
   }
   .prod_content p
   {
    text-align: center;
   }
}

@media screen and (max-width: 400px){
    .product_card h1
    {
    font-size: 22px;
    }
    .product_tagline h2
   {
    font-size: 20px;
   }
     .prod_content h1
   {
    font-size: 26px;
   }
   .prod_content p
   {
    font-size: 13px;
   }
}