
.carousel-container {
  max-width: 85%;
  /* margin-bottom: 5rem; */
  position: relative;
}

.slide-container {
 
  display: flex !important;
   justify-content: center;
  align-items: center;
  flex-direction: column !important;
  /* width: 80%; */
  /* padding: 20px 0rem 20px 0rem;  */
   margin-top: 2.6rem;
margin-bottom: 2.6rem;
}

.second-cont {
 /* border: 2px solid #fff; */
  margin: 20px 2rem 20px 2rem; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(255, 236, 255, 0.101);
    border: 2px solid rgba(255, 255, 255, 0.125);
    border-radius: 20px;
    cursor: text;
    padding: 25px; */
}

.slide-img {
  height: 11rem;
  width: 50%;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.slide-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
   border-radius: 50%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
   flex: 1; 
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  text-align: start;
}

.content h2 {
  text-align: center;
  /* font-size: 30px; */
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  /* color: var(--secondaryText);
  color: #182268; */
}

.content p{
  /* font-size: 20px; */
  /* color: var(--primaryText); */
 text-align: center;
 color: var(--secondaryText);
 font-size: 16px;
}


.content a {
  background-color: var(--button);
  color: var(--white);
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
}

.carousel-container .slick-dots li button:before {
  color: #000;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -4rem;
}
.carousel__btn--next {
  right: -4rem;
}

.carousel__btn-arrow {
  border: solid var(--primaryText);
  /* background-color: #c1272d; */
  border-width: 0 0.2rem 0.2rem 0;
  height: 0.8rem;
  padding: 3px;
  width: 0.8rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
  margin-left: 1rem;
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
  margin-right: 1rem;
}



.slick-prev, .slick-next  {
    font-size: 50px;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px !important;
    height: 50px !important;
    padding: 5px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #000 !important;
    outline: none;
    border-radius: 50%;
    background: #000 !important;
}

.slick-next::before {
     width: 50px !important;
    height: 50px !important;
}

.slick-prev {
    left: -50px !important;
}

.slick-dots {
  bottom: 25px;
}

.slick-dots li
{
   height: 10px !important;
  width: 10px !important;
  border-radius: 25px;
  background-color: #fff;
  margin-right: 8.5px !important;
  margin-left: 7.5px !important;
  cursor: pointer;
  margin-bottom: 1.4rem !important;
}

.slick-dots li button:before
{
  font-size: 0px !important;
}
.slick-dots .slick-active
{
   background-color: #000;
}

@media screen and (max-width: 1200px) {
  .slide-img {
    height: 9rem;
    width: 70%;
  }
}
@media screen and (max-width: 1050px) {
  .slide-img {
    height: 10rem;
    width: 60%;
  }
}
@media screen and (max-width: 1100px) {
  .carousel__btn--prev {
  left: -4rem;
}
}
@media screen and (max-width: 950px) {
  /* .slide-img {
  height: 10rem;
} */
.carousel__btn-arrow {
  height: 1.2rem;
  padding: 3px;
  width: 1.2rem;
  z-index: 10;
  margin-left: 5px;
  margin-right: 5px;
}
.carousel__btn-arrow {
  /* height: 1.2rem; */
  padding: 3px;
  /* width: 1.2rem; */
  z-index: 10;
}
.carousel__btn--prev {
  left: -3rem;
}
.carousel__btn--next {
  right: -3rem;
}
.content h2 {
  font-size: 22px;
}

.content p{
 font-size: 15px;
}
}


@media screen and (max-width: 750px) {
  .carousel__wrap {
  left:47%;
  width: 70%;
}
.carousel__container {
 
  width: 100%;
}
 .slide-img {
    height: 9rem;
    width: 70%;
  }
  .carousel__wrap {
  left:47%;
  width: 70%;
}
.carousel__container {
 
  width: 100%;
}
}

@media screen and (max-width: 700px) {
  .slide-container{
    margin-top: 0rem;
    margin-bottom: 3rem;
    padding: 0;
  }
  .second-cont {
    margin: 0;
  }
  .second-slider1 {
    margin: 1rem;
  }
    .carousel__btn--prev {
  left: -2rem;
}
  .carousel__btn--next {
  right: -2rem;
}
.slick-dots {
  bottom: 0px;
}
}
@media screen and (max-width: 600px) {
 .slide-container{
  flex-direction: column;
 }
 .slide-img {
  width: 80%;
 }

 .content {
  row-gap: 1rem;
 }
}

@media screen and (max-width: 570px)  {
   .slide-container {
  width: 70%;
}
 .slide-img {
  width: 40%;
  height: 8rem;
 }
     .carousel__btn--prev {
  left: -2rem;
}
  .carousel__btn--next {
  right: -2rem;
}
/* .client-slide-img {
  height: 14rem;
  width: 80%;
} */
.client-slide-img{
  height: 10rem;
  width: 80%;
}
.object-fit-image {
  height: 14rem;
}
}

@media screen and (max-width: 450px) {
  .carousel__btn-arrow {
  height: 0.4rem;
  padding: 3px;
  width: 0.4rem;
  z-index: 10;
}
}
@media screen and (max-width: 400px) {
  .slide-container{
    margin-bottom: 2rem;
  }
 .content h1{
  font-size: 22px;
}
.content p{
  font-size: 12px;
}
.content a{
  font-size: 10px;
}
.slick-dots {
  bottom: -10px;
}
     .carousel__btn--prev {
  left: -1rem;
}
  .carousel__btn--next {
  right: -1rem;
}
}
