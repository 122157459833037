@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=EB+Garamond&family=Lobster&family=Montserrat&display=swap');

.slideshow-cont {
    border-radius: 10px;
    bottom: 40px;
    color: #fff;
    font-size: 40px;
    left: 45%;
    margin-left: -40%;
    padding: 10px;
    position: absolute;
    text-align: center;
    transition: opacity .35s ease-in-out;
    width: 80%;
}

.carousel .thumbs-wrapper {
    display: none !important;
}

/* Adding the black translucent overlay */
.slide-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); 
     /* background-color: rgba(184, 127, 58, 0.3);  */
    z-index: 0;
}

.slide-card:hover::before{
    background-color: rgba(0, 0, 0, 0.5); 
    transition: 0.5s ease-in-out;
}

.slide-card p{
   font-family: 'EB Garamond', serif;
    font-size: 50px;
}

.carousel .thumbs-wrapper {
    display: none !important;
}
.carousel .slide img {
    height: 100% !important;
    object-fit: cover !important;
}

@media screen and (min-width: 950px)
{
    .slide-cont {
    height: 90vh;
    width: 100%;  
    }
    .slide-card {
    height: 90vh;
    width: 100%; 
    }    
}

@media screen and (max-width: 1300px)
{
    .slide-cont {
    height: 90vh;
    width: 100%;  
    }
    .slide-card {
    height: 90vh;
    width: 100%; 
    }    
}

@media screen and (max-width: 950px) {
    .slide-card p {
        font-size: 40px;
    }
}

 @media screen and (max-width: 780px) {
     .slide-cont {
    height: 70vh;
    width: 100%;    
    }
    .slide-card {
    height: 70vh;
    width: 100%;
    }
     .slide-card p {
        font-size: 35px;
    }
} 

@media screen and (max-width: 700px)
{
    .slide-cont {
    height: 60vh;
    width: 100%;  
    }
    .slide-card {
    height: 60vh;
    width: 100%; 
    }    
    .slideshow-cont
    {
        width: 90%;
    }
      .slide-card p {
        font-size: 30px;
    }
}


 @media screen and (max-width: 620px) {
     .slide-cont {
    height: 55vh;
    width: 100%;    
    }
    .slide-card {
    height: 55vh;
    width: 100%;
    }
    .slide-card p {
        font-size: 28px;
    }
} 
 @media screen and (max-width: 540px) {
     .slide-cont {
    height: 50vh;
    width: 100%;    
    }
    .slide-card {
    height: 50vh;
    width: 100%;
    }
     .slide-card p {
        font-size: 26px;
    }
} 
 @media screen and (max-width: 460px) {
     .slide-cont {
    height: 45vh;
    width: 100%;    
    }
    .slide-card {
    height: 45vh;
    width: 100%;
    }
    .slide-card p {
        font-size: 24px;
    }
} 
 @media screen and (max-width: 400px) {
     .slide-cont {
    height: 42vh;
    width: 100%;    
    }
    .slide-card {
    height: 42vh;
    width: 100%;
    }
     .slide-card p {
        font-size: 20px;
    }
} 



/* @media screen and (min-width: 700px)
{
    .slide-cont {
    height: 90vh;
    width: 100%;    
    }
    .slide-card {
    height: 90vh;
    width: 100%;
    }
    
}
@media screen and (max-width: 800px) {
    .slideshow-cont {
        width: 65%;
        font-size: 35px;
    }
}
@media screen and (max-width: 620px) {
    .slideshow-cont {
        width: 65%;
        font-size: 27px;
    }
}
@media screen and (max-width: 520px) {
    .slideshow-cont {
        width: 80%;
        font-size: 23px;
    }
}
@media screen and (max-width: 400px) {
    .slideshow-cont {
        width: 85%;
        font-size: 18px;
        left: 50%;
    }
} */