/* :root {
  --secondaryBackgroundClr: #e7e6e6;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:#000;
  --secondaryText:#182268;
  --white:#fff;
  --button:#c1272d;
} */
.client-carousel-container {
  max-width: 85%;
  /* margin-bottom: 5rem; */
  position: relative;
}

.slide-container {
 
  display: flex !important;
  flex-direction: column !important;
  /* width: 80%; */
  /* padding: 20px 0rem 20px 0rem;  */
}

.second-cont {
 /* border: 2px solid #fff; */
  margin: 0px 1rem 0px 1rem; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-slide-img{
  height: 10rem;
  width: 100%;
  position: relative;
  border-radius: 10px;
}


.client-slide-img img {
  width: 100%;
  height: 100%;
  display: block;
  /* object-fit: cover; */
  border-radius: 10px;
}

.object-fit-image {
  height: 20rem;
}

.object-fit-image img{
  object-fit: cover;
}

.client-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  padding-top: 20px;
}

.client-content h2 {
  text-align: center;
  /* font-size: 30px; */
  font-weight: 700;
  color: var(--secondaryText);
  color: #fff;
  text-transform: capitalize;
}

.client-carousel-container .slick-dots li button:before {
  color: #000;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -4rem;
}
.carousel__btn--next {
  right: -4rem;
}

.carousel__btn-arrow {
  border: solid var(--primaryText);
  /* background-color: #c1272d; */
  border-width: 0 0.2rem 0.2rem 0;
  height: 1.5rem;
  padding: 3px;
  width: 1.5rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}



.slick-prev, .slick-next  {
    font-size: 50px;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px !important;
    height: 50px !important;
    padding: 5px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #000 !important;
    outline: none;
    border-radius: 50%;
    background: #000 !important;
}

.slick-next::before {
     width: 50px !important;
    height: 50px !important;
}

.slick-prev {
    left: -50px !important;
}

.custom-dot {
  width: 20px !important; 
  height: 20px !important; 
  /* background-color: #fff !important; 
  border-radius: 50% !important;
  margin: 0 5px;  */
}

@media screen and (max-width: 1220px) {
  /* .carousel__btn--prev {
  left: -8rem;
} */
}
@media screen and (max-width: 1100px) {
  .carousel__btn--prev {
  left: -4rem;
}
}
@media screen and (max-width: 950px) {
  .slide-container
  {
    margin-top: 0rem !important;
  }
  .client-slide-img{
  height: 6rem;
}
.object-fit-image {
  height: 17rem;
}
.carousel__btn-arrow {
  height: 1.2rem;
  padding: 3px;
  width: 1.2rem;
  z-index: 10;
  margin-left: 5px;
  margin-right: 5px;
}
.carousel__btn--prev {
  left: -3rem;
}
.carousel__btn--next {
  right: -3rem;
}
}
/* @media screen and (max-width: 900px) {
  .carousel__btn--prev {
  left: -4.5rem;
}
} */


@media screen and (max-width: 750px) {
  .carousel__wrap {
  left:47%;
  width: 70%;
}
.carousel__container {
 
  width: 100%;
}
}
@media screen and (max-width: 700px) {
  .client-carousel-container {
  max-width: 90%;
}
  .carousel__btn--prev {
  left: -1rem;
}
  .carousel__btn--next {
  right: -1rem;
}
}
@media screen and (max-width: 700px) {
  .slide-container {
  width: 90%;
  padding: 20px 1rem 20px 1rem; 
}
}
@media screen and (max-width: 600px) {
 .slide-container{
  flex-direction: column;
 }
 .client-slide-img {
  width: 100%;
 }

 .client-content {
  row-gap: 1rem;
 }
}

@media screen and (max-width: 570px)  {
   .slide-container {
  width: 70%;
  padding: 20px 2rem 20px 2rem; 
}
}

@media screen and (max-width: 450px) {
  .client-carousel-container {
  margin-bottom: 2rem;
}
.carousel__btn-arrow {
  height: 1rem;
  padding: 3px;
  width: 1rem;
  z-index: 10;
  margin-left: 5px;
  margin-right: 5px;
}

 /* .slide-img.full-width {
  width: 90% !important;
   height: 12rem;
} */
}
@media screen and (max-width: 400px) {
 .client-content h1{
  font-size: 22px;
}
.client-content p{
  font-size: 12px;
}
.client-content a{
  font-size: 10px;
}
}
