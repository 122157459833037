body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 50px;
  letter-spacing: 2px;
  font-family: 'Times New Roman', sans-serif;
  padding: 0;
  margin: 0;
}
h2{
  font-size: 25px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
p{
  font-size: 18px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}