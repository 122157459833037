.slider-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 80vh;
}

.product-slider-content h1
{
  color: #fff;
  text-align: center;
}
.product-slider-content p
{
  margin: 20px;
  text-align: center;
}

.left-slide {
  height: 100%;
  width: 45%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-in-out;
}

.left-slide > div {
  height: 100%;
  width: 100%;
  /* background-color: rgb(203,203,203); */
  /* background-image:url(https://fnsinternational.com/wp-content/uploads/2022/12/texture.webp); */
  background-image:url(../../assets/images/Home/texture1.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.left-slide h1 {
  font-size: 55px;
  margin-bottom: 10px;
  margin-top: -30px;
}

.right-slide {
  height: 100%;
  position: absolute;
  top: 0;
  left: 45%;
  width: 65%;
  transition: transform 0.5s ease-in-out;
}

.right-slide > div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

button {
  /* background-color: #fff; */
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  padding: 0px;
}

/* button:hover {
  color: #222;
} */

button:focus {
  outline: none;
}

.slider-container .action-buttons button {
  position: absolute;
  left: 45%;
  top: 50%;
  z-index: 100;
}

.slider-container .action-buttons .down-button {
  transform: translateX(-100%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.slider-container .action-buttons .up-button {
  transform: translateY(-100%);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.back-img {
    background-image: url(../../assets/images/products/kitchen/kitchen_main.jpg);
}
.back-img2 {
    background-image: url(../../assets/images/products/cooking/cooking_main.jpg);
}
.back-img3 {
    background-image: url(../../assets/images/products/paper/paper_main.jpg);
}
.back-img4 {
    background-image: url(../../assets/images/products/lineware/lineware_main.jpg);
}
.back-img5 {
    background-image: url(../../assets/images/products/fnb/fnb_main.jpg);
}
.back-img6 {
    background-image: url(../../assets/images/products/housekeeping/housekeeping_main.jpg);
}

 @media screen and (max-width: 950px){
  .left-slide h1 {
    font-size: 40px;
  }
  .left-slide p{
    font-size: 16px;
  }
 }
 @media screen and (max-width: 850px){
  .left-slide h1 {
    font-size: 35px;
  }
  .left-slide p{
    font-size: 15px;
  }
 }
 @media screen and (max-width: 650px){
  .left-slide h1 {
    font-size: 30px;
  }
  .left-slide p{
    font-size: 14px;
  }
 }
 @media screen and (max-width: 500px){
  .left-slide{
    width: 40%;
  }
  .right-slide{
    left: 40%;
  }
  /* .right-slide > div{
    background-size: contain;
    background-color: black;
  } */
  .slider-container .action-buttons button{
    left: 40%;
  }
  /* .left-slide > div  {
    height: 50%;
  } */
  /* .right-slide > div{
    height: 50%;
  } */

  .left-slide h1 {
    font-size: 26px;
  }
  /* .product-slider-content h1{
    margin-bottom: 10rem;
  } */
  .left-slide p{
    font-size: 12px;
    /* display: none; */
  }
 }

  @media screen and (max-width: 425px)
  {
    .left-slide{
    width: 50%;
  }
  .right-slide{
    left: 50%;
  }
  .slider-container .action-buttons button{
    left: 50%;
  }
  }