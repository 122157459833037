
.navbar {
background-color: rgba(0, 0, 0, 0.5);
  /* backdrop-filter: blur(10px); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #fff;
  /* padding: 1rem; */
  /* margin-top: 2rem;  */
  /* transition: transform 0.5s ease-in-out;  */
    transition: transform 0.5s;
    transform: translateY(-100%);
}

.atTop {
   transform: translateY(0%);
   background-color: transparent;
   position: absolute;
   /* backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(0, 0, 0, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.125); */
}

.navbar:hover {
  /* background-color: #3a4b5d; */
  background-color: #000;
  transition: 0.5s ease-in-out;
}

/* .atTop:hover {
  background-color: transparent;
} */

.active-scroll {
  /* background-color: rgba(58, 75, 93, 0.7);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);  */
  /* transition: 0.5 ease-in-out; */
  color: #fff;
  margin-top: 0rem;  
   transform: translateY(0%);
   position: fixed;
  /* backdrop-filter: blur(10px); */
   backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.125);
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  
}
.navbar .nav-logo {
  background-color: transparent;
}

.nav-logo {
  align-items: center;
  margin-left:  2rem;
  /* margin-top: 1rem; 
  margin-bottom: 1rem;  */
}

.atTop .nav-logo {
 /* background-color: #fff; */
  margin-top: 1rem; 
  margin-bottom: 1rem; 
}

.atTop:hover .nav-logo {
  background-color: transparent;
}



.nav-logo img {
   height: 6.5rem;
    cursor: pointer;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  /* text-align: center; */
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5rem 2rem;
  /* margin-right: 2rem; */
  height: 100%;
  border-bottom: 3px solid transparent;
}

.atTop .nav-links {
  /* color: #3a4b5d;
  color: #fff; */
  /* color: #9DA5AE; */
  color: #ffffffd7;
}

.atTop:hover .nav-links {
  color: #fff;
}

.fa-code {
  margin-left: 0.5rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #fff;
}

.nav-item .active {
  color: #fff;
}
.atTop .nav-item .active {
  color: #fff;
}
.atTop:hover .nav-item .active {
  color: #fff;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 1013px) {
  /* .nav-item {
  margin-right: 0.6rem;
} */
}

@media screen and (max-width: 800px) {
  .navbar {
    /* height: 80px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding: 0; */
}
.atTop .nav-logo
{
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.nav-logo img {
  height: 5rem;
}
  .nav-menu {
    display: none;
    flex-direction: column;
    width: 30%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 66px;
    right: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-right: 0;
  }

  .nav-menu.active {
    display: flex;
    flex-direction: column;
   background-color: rgba(0, 0, 0, 1);
    right: 0px;
    opacity: 1;
     transition: 0.5s ease-in-out;
    z-index: 1;
  }
  .nav-item .active {
    color: #e7e6e6;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
    margin-right: 0;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #e7e6e6;
    margin-top: 0.2rem;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .nav-menu {
    width: 50%;
    top: 53px;
  }
  .nav-logo img {
  height: 4.2rem;
}
.nav-icon {
  font-size: 1rem;
}
.nav-links {
  font-size: 16px;
  padding: 1rem;
}
}