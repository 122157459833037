.vendor_sec
{
    padding: 2rem 0rem 1rem 0rem;
    margin: 2rem 0rem 0rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.last-sec
{
    margin-bottom: 4rem;
    height: 4rem;
}
.getintouch
{
    padding: 0rem 1rem 1rem 1rem;
}

.form-heading p b
{
    text-decoration: underline;
    cursor: pointer;
}

.getintouch_whole
{
    width: 75%;
    padding: 5rem 3rem 6rem 3rem;
    border: 1px solid #000;
    border-radius: 25px;
     display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 5rem;
}

.getintouch_heading h1 {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 100%;
}

.getintouch_content
{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.getintouch_content table
{
    font-size: 20px;
    width: 100%;
    text-align: left;
}
.getintouch_content table tr th{
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.getintouch_content table tr td p{
    margin-left: 20px;
    margin-bottom: 20px;
}
.getintouch_content table tr td a{
    margin-left: 0px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #000;
    font-size: 20px;
}
.getintouch_content p {
    font-size: 20px;
    width: 100%;
    text-align: left;
}
.getintouch_content table tr td .links
{
    font-size: 2rem;
    margin-left: 2rem;
}
.getintouch_content  {
    font-size: 20px;
    width: 100%;
    text-align: left;
    text-decoration: none;
}

.apply-now
{
    position: relative;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.apply-img::after
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
    border-radius: 25px;
}
.apply-img img
{
    position: relative;
    height: 20vh;
    width: 100%;
}
.apply-now a
{
    text-decoration: none;
    position: absolute;
    font-size: 32px;
    color: #fff;
    padding: 2rem 5rem 2rem 5rem;
    background:url(../../assets/images/Contact/contact_back.jpg);
    border-radius: 25px;
}


@media screen and (max-width: 950px){
    .getintouch_whole
    {
        width: 90%;
        column-gap: 3rem;
    }
    .getintouch_heading h1
    {
        font-size: 42px;
    }
    .getintouch_content p
    {
        font-size: 19px;
    }
    .getintouch_content table tr td a
    {
        font-size: 19px;
    }
    .apply-now a {
        font-size: 28px;
    }
}
@media screen and (max-width: 850px){
    .getintouch_whole
    {
        width: 90%;
        column-gap: 2rem;
    }
    .getintouch_heading h1
    {
        font-size: 40px;
    }
    .getintouch_content p
    {
        font-size: 18px;
    }
    .getintouch_content table tr td a
    {
        font-size: 18px;
    }
    .last-sec{
        height: 1rem;
    }
    .apply-now{
        width: 90%;
    }
     .apply-now a {
        font-size: 24px;
        padding: 2rem 3rem 2rem 3rem;
    }
}
@media screen and (max-width: 700px){
     .getintouch_whole
    {
        width: 90%;
        column-gap: 1rem;
        padding: 2rem 2rem 2rem 2rem;
    }
    .getintouch_heading h1
    {
        font-size: 38px;
    }
    .getintouch_content p
    {
        font-size: 16px;
    }
    .getintouch_content table tr td a
    {
        font-size: 16px;
    }
}

@media screen and (max-width: 550px)
{       
    .getintouch_whole
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;
    }
}

@media screen and (max-width: 450px){
     .getintouch_whole
    {
        padding: 1rem;
    }
    .getintouch_heading h1
    {
        font-size: 36px;
    }
    .getintouch_content p
    {
        font-size: 14px;
    }
    .getintouch_content table tr td p {
        margin-left: 0.7rem;
        margin-bottom: 0;
    }
    .getintouch_content table tr td a
    {
        font-size: 14px;
    }
    .getintouch_content table tr td .links{
        font-size: 1.5rem;
    }
    .apply-now a {
        font-size: 20px;
        padding: 1rem 2rem 1rem 2rem;
    }
}