
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Satisfy&display=swap');

:root {
  --secondaryText: #dddde2;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:rgba(255, 216, 107, 0.9);
  --secondaryBackgroundClr:rgba(58, 75, 93, 0.9);
  --white:#fff;
  --button:#c1272d;
}

section {
   margin-top: 2rem;
}
.section-main {
     margin-bottom: 2rem;
     /* margin-top: 0 !important; */
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
}
.section-top {
     margin-top: 2rem !important;
}
.section-head {
    color: var(--secondaryBackgroundClr);
    font-weight: 700;
  border-radius: 20px;
}
.section-container {
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../../assets/images/Home/cuttlery.jpg); */
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url(../../assets/images/About/mv_back.jpg);
   background-attachment: fixed;
   background-size: cover;
   background-position: top;
   /* animation: backgroundMovement 3s linear infinite alternate; */
   /* animation: zoomBackground 3s linear infinite alternate; */
}
/* .product-section {
    height: 80%;
} */
.product-section h1{
    color: var(--secondaryBackgroundClr);
}
.section-container h1 {
    color: var(--primaryText) ;
    margin-top: 1rem;
    text-align: center;
}
/* @keyframes backgroundMovement {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 30px 30px; 
    }
} */
@keyframes zoomBackground {
    0% {
        background-size: 100%;
    }
    100% {
        background-size: 110%; /* Adjust the value as needed for the desired zoom level */
    }
}

/******************* ABOUT SECTION *********************/

.about {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(../../assets/images/About/mv_back.jpg);
   /* background: linear-gradient(rgba(58, 75, 93, 0.9), rgba(0, 0, 0, 0.5)), url(../../assets/images/Home/about_back.png); */
   background-attachment: fixed;
   background-size: cover;
   background-position: 47% 50%;
}
.about .about-img {
position: absolute;
width: 100%;
height: 100%;
}
.about-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -111;
}

/* Adding the black translucent overlay */
.about .about-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); 
     /* background-color: rgba(184, 127, 58, 0.3);  */
    z-index: 0;
}

.about-content {
    /* position: relative; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    row-gap: 2rem;
    padding: 2rem 0 2rem 0;
}

.about-tagline {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.about-tagline h1 {
    font-size: 60px;
    /* font-family: 'Dancing Script', cursive; */
    font-family: 'Satisfy', cursive;
    letter-spacing: 2px;
    color: #fff;
     /* background-color: rgba(105, 83, 22, 0.9); */
     /* background-color: rgba(58, 75, 93, 1); */
    padding: 1rem;
    /* border-radius: 25px; */
    word-spacing: 5px;
    font-weight: 700;
    text-align: center;
    /* backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(255, 236, 255, 0.101);
    border: 2px solid rgba(255, 255, 255, 0.125);
    border-radius: 20px; */
    cursor: text;
}

.about-card-whole {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
}

.about-content .about-card-cont {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    /* background-color: rgba(58, 75, 93, 0.9); */
    /* background-color: rgba(105, 83, 22, 0.9); */
    backdrop-filter: blur(16px) saturate(200%);
  -webkit-backdrop-filter: blur(16px) saturate(200%);
  background-color: rgba(255, 236, 255, 0.101);
  border: 2px solid rgba(255, 255, 255, 0.125);
  border-radius: 20px;
  cursor: text;
    width: 50%;
    padding: 2rem;
    color: #fff;
    /* margin-right: 2rem; */
    /* margin-bottom: 4rem; */
     border-radius: 20px;
}

.about-content .left-cont {
    align-self: flex-start; 
     margin-left: 2rem;
    margin-bottom: 2rem;
}

.left-cont{
    margin-top: 2rem;
}
.about-card-cont .about-card {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;   
}
.about-card h1 {
    color: #fff;
    font-weight: 600;
    font-size: 31px;
    font-family: 'Satisfy', cursive;
    letter-spacing: 5px;
}
.about-card p {
    color: var(--secondaryText);
}

 @media screen and (max-width: 950px){
    .section-main{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .section-container h1 {
        font-size: 40px;
    }
    .product-section h1 {
        font-size: 40px;
    }
    .about-content{
        row-gap: 1rem;
    }
    .about-tagline h1
    {
        font-size: 50px;
        letter-spacing: 1px;
          word-spacing: 2px;
    }
    .about-card h1{
        font-size: 28px;
    }
    .about-card p {
        font-size: 16px;
    }
 }
 @media screen and (max-width: 850px){
    .about-content{
        padding: 1rem 0 1rem 0;
    }
    .about-tagline h1
    {
        font-size: 40px;
    }
    .about-card h1{
        font-size: 24px;
    }
    .about-card p {
        font-size: 14px;
    }
    .about-content .about-card-cont {
        padding: 1rem;
    }
    .about-card-cont .about-card
    {
        row-gap: 1rem;
    }
 }

 @media screen and (max-width: 650px){
    .section-main{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .section-container h1 {
        font-size: 30px;
    }
    .product-section h1 {
        font-size: 30px;
    }
    .about-content{
        row-gap: 1rem;
        padding: 1rem 0 1rem 0;
    }
    .about-tagline h1
    {
        font-size: 35px;
    }
    .about-card h1{
        font-size: 22px;
    }
 }

  @media screen and (max-width: 450px) {
    .section-main{
        margin-bottom: 1rem;
    }
    .about-content .about-card-cont
    {
        align-self: center;
        width: 80%;
    }
    .about-content .left-cont {
        margin: 1rem 0 1rem 0;
    }
    .about-tagline h1
    {
        font-size: 30px;
        letter-spacing: 1px;
          word-spacing: 2px;
    }
    .about-card h1{
        font-size: 18px;
    }
     .about-card p {
        font-size: 12px;
    }
  }

    @media screen and (max-width: 380px) {
         .section-main{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    }