:root {
  --secondaryText: #dddde2;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:rgba(255, 216, 107, 0.9);
  --secondaryBackgroundClr:rgba(58, 75, 93, 0.9);
  --white:#fff;
  --button:#c1272d;
}

.section-about {
    padding: 0rem 6rem 0rem 6rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
/************** INTRODUCTION ******************/


/************** INTRODUCTION ******************/
.intro {
    display: flex;
    column-gap: 3rem;
}
.intro-est
{
  /* column-gap: 0rem; */
   margin-top: 0rem;
}
.intro-img {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.img-est
{
    width: 50%;
}

.image-inside1{
    height: 35rem;
    object-fit: cover;
    border-radius: 25px 25px 25px 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.intro-content {
    align-self: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    width: 50%;
    /* margin-top: -10px; */
}
.intro-content h1{
    font-size: 35px;
}
.intro-content p {
    color: #000;
    /* font-family: Montserrat; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
.intro-content h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    /* line-height: 42px; */
    font-style: italic;
    margin-top: 0;
}
/* .f-intro-para {
    line-height: 25px;
} */

/******************* WHAT WE DO **********************/

.what-we-do
{
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(../../assets/images/About/about_back.jpg);
   background-attachment: fixed;
   background-size: cover;
   background-position: 0 10%;
   padding: 6rem;
}
.what-content-2 {
width: 50%;
border-radius: 25px 0px 25px 25px;
    /* background-color: #000; */
    /* background-image: url(../../assets/images/Home/texture.jpg); */
     backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(255, 236, 255, 0.101);
    border: 2px solid rgba(255, 255, 255, 0.125);
    padding: 2rem;
}
.what-content-2 h1 {
    color: #fff;
}
.what-content-2 p{
color: #fff;
}

/***************** MISSION VISION *****************/
.mission-vision{
     
      padding: 3rem 8rem 3rem 8rem;
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(../../assets/images/About/mv_back.jpg);
      background-position: 0 10%;
   background-attachment: fixed;
   background-size: cover;
   background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4rem;
}
.mv-whole{
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    width: 100%;
}
.mv-heading {
    
     background-size: cover;
    height: 8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px 0 0 0;
}
/* .heading-mission{
 background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../assets/images/About/mission2.jpg);
}
.heading-vision
{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/images/About/mission3.jpg);
    background-position: bottom;
} */
.mv-card {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    border-radius: 25px 0 25px 25px;
    /* background-color: #CFCFCF; */
    backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(255, 236, 255, 0.101);
    border: 2px solid rgba(255, 255, 255, 0.125);
    padding: 0 0rem 1.2rem 0rem;
}


.mv-whole{
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    width: 100%;
}

.mv-card
{
    width: 50%;
}

.mv-heading {
     
     background-size: 100%;
     background-position: center;
     background-size: cover;
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px 25px 0px 0;
}
/* .mission-img
{
    background-image: url(../../assets/images/company/mission.png);
    position: relative;
}
.vision-img
{
    background-image: url(../../assets/images/company/vision.png);
     position: relative;
} */
.black-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); 
  z-index: 0; 
  border-radius: 25px;
}
.mv-heading h1{
    color: #fff;
    font-size: 34px;
    font-family: 'Montserrat', sans-serif;
}

.mv-card h1 {
   color: #fff;
   font-weight: 700;
   font-size: 34px;
   font-family: 'Montserrat', sans-serif;
   z-index: 1;
}


.mv-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.7rem;
    padding: 0 1rem 0rem 1rem;
    width: 100%;
}

.mv-content .para {
    font-weight: 700;
    margin-bottom: 1rem;
}
.mv-content p {
text-align: center;
font-style: normal;
font-size: 18px;
line-height: normal;
color: #fff;
/* text-transform: uppercase; */
}
.mv-content h2 {
    color: var(--secondaryText);
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.priority {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.priority p {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem 1rem 4rem;
    width: 2rem;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
}


@media screen and (max-width: 1150px)
{
    .mv-content
    {
        width: 90%;
    }
    .priority
    {
        column-gap: 1rem;
    }
    .priority p
    {
        width: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .intro{
        column-gap: 2rem;
    }
     .section-about {
        padding: 3rem 2rem 3rem 2rem;
    }
    .intro-est
    {
       padding: 2rem 0rem 2rem 0rem !important;
    }
    .intro-img
    {
        width: 40%;
    }
    .intro-content
    {
        width: 60%;
    }
    .what-content-2 {
        margin: 2rem;
    }
}
@media screen and (max-width: 950px){
    .intro-content h1{
        font-size: 30px;
    }
    .intro-content p{
        font-size: 16px;
    }
     .mv-card h1
    {
        font-size: 32px;
    }
   .mv-content p
   {
    font-size: 17px;
   }
    .priority p
    {
        font-size: 15px;
    }
    .mv-heading h1
    {
        font-size: 32px;
    }
}
@media screen and (max-width: 850px){
    .intro-content h1{
        font-size: 30px;
    }
    .intro-content p{
        line-height: 20px;
    }
     .mv-card h1
    {
        font-size: 30px;
    }
   .mv-content p
   {
    font-size: 16px;
   }
    .priority p
    {
        font-size: 14px;
    }
    .priority p
    {
        width: 0rem;
    }
    .mv-heading h1
    {
        font-size: 30px;
    }
}
@media screen and (max-width: 800px)
{
    .priority p
    {
        padding: 1rem 5rem 1rem 5rem;
    }
}
@media screen and (max-width: 740px)
{
    .intro-content h1{
        font-size: 28px;
        letter-spacing: normal;
    }
    .intro-content p
    {
        font-size: 14px;
    }
    .f-intro-para {
        line-height: normal !important;
    }
    .intro
    {
        column-gap: 1rem;
    }
    .intro-img
    {
        width: 50%;
    }
    .intro-content
    {
        width: 60%;
    }
}

@media screen and (max-width: 700px)
{
    .mv-card h1
    {
        font-size: 28px;
    }
   .mv-content p
   {
    font-size: 15px;
   }
   .priority
   {
        row-gap: 0.5rem;
   }
    .priority p
    {
        font-size: 13px;
    }
    .mv-heading h1
    {
        font-size: 28px;
    }
}

@media screen and (max-width: 600px)
{
    .intro
    {
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    .intro-img {
    width: 80%;
}
    .image-inside1{
    height: 18rem;
}
     .intro-content
    {
        width: 90%;
    }
    .what-content-2 {
        width: 80%;
        margin: 3rem 0rem 3rem 0rem;
    }
    .mv-heading
    {
        height: 5rem;
    }
    .mv-content
    {
        row-gap: 0.4rem;
    }
    .mv-whole{
    row-gap: 2rem;
    flex-direction: column;
    margin-top: 1rem;
}
.mv-card
{
    width: 100%;
}
}
@media screen and (max-width: 400px)
{
    .intro
    {
        flex-direction: column;
        row-gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    .intro-img {
    width: 100%;
    }
    .image-inside1{
    height: 16rem;
}
.intro-content{
    width: 100%;
    padding: 0;
}
    .intro-content p
    {
        font-size: 14px;
        text-align: left;
    }
    .what-content-2{
        width: 90%;
        padding: 1rem;
    }
     .mv-card h1
    {
        font-size: 26px;
    }
   .mv-content p
   {
    font-size: 14px;
   }
    .priority p
    {
        font-size: 12px;
    }
    .mv-heading h1
    {
        font-size: 26px;
    }
}